<template>
  <div class="pagination" v-if="pagination?.last_page > 1">
    <NuxtLink
      class="btn-page btn-page-arrow"
      :to="{ query: {...$route.query, page: pagination.current_page - 1} }"
      :class="{'btn-page-arrow--disabled': pagination.current_page <= 1}"
    ><IconChevronLeft class="icon" /></NuxtLink>
    <div class="pagination__pages">
      <template v-for="page in pages">
        <a v-if="page === null" class="btn-page btn-page--disabled">...</a>
        <NuxtLink
          v-else
          :to="{ query: {...$route.query, page} }"
          :class="{'btn-page--active': page === pagination.current_page}"
          class="btn-page"
        >{{ page }}</NuxtLink>
      </template>
    </div>
    <NuxtLink
      class="btn-page btn-page-arrow"
      :to="{ query: {...$route.query, page: pagination.current_page + 1} }"
      :class="{'btn-page-arrow--disabled': pagination.current_page >= pagination.last_page}"
    ><IconChevronRight class="icon" /></NuxtLink>
  </div>
</template>

<script setup>
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue'

const props = defineProps({
  pagination: Object,
  range: {
    type: Number,
    default: 5
  },
  server: Boolean
})

const { isMobile } = useBreakpoints()

const range = computed(() => !props.server && isMobile.value ? 1 : props.range)

const nearest = computed(
  () => Array.from({ length: range.value })
    .map((_, i) => props.pagination.current_page - Math.floor(range.value / 2) + i)
    .filter(n => n > 1 && n < props.pagination.last_page)
)
const pages = computed(
  () => [1]
    .concat(nearest.value[0] > 2 ? [null] : [])
    .concat(nearest.value)
    .concat(nearest.value[nearest.value.length - 1] < props.pagination.last_page - 1 ? [null] : [])
    .concat(!nearest.value.length && props.pagination.last_page > 2 ? [null] : [])
    .concat([props.pagination.last_page])
)
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0;

  &__pages {
    display: flex;
    align-items: center;
  }
}

.btn-page {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-elements-primary);
  position: relative;
  &::after {
    content: '';
    position: absolute;
    inset: auto 0 0;
    height: 5px;
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: .2s transform;
    background: var(--color-primary);
  }
  &:hover {
    opacity: 1;
    &::after {
      transform: scaleY(.5);
    }
  }
  &--active::after {
    transform: scaleY(1) !important;
  }
  &--disabled {
    pointer-events: none;
  }
}

.btn-page-arrow {
  height: 42px !important;
  border-radius: 50%;
  background: var(--color-primary);
  color: var(--color-bg);

  &::after {
    display: none;
  }
  .icon {
    width: 18px;
    height: 18px;
  }
  &--disabled {
    background: var(--color-bg-secondary);
    pointer-events: none;
  }
}
</style>

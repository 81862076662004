
export default async function usePagination (loadData = async () => ({}), perPage = ref(10), { immediate = true, key = null } = {}) {
  const router = useRouter()
  const route = useRoute()
  const page = computed({
    get: () => +(route?.query.page ?? 1),
    set: page => router.replace({ ...route, query: { ...route.query, page } })
  })

  watch(perPage, () => page.value = 1)

  const { data, pending: loading, refresh: load, error } = await useAsyncData(...[
    ...(key ? [key] : []),
    () => loadData(unref(page), unref(perPage)),
    { immediate }
  ])

  const items = computed(() => data.value?.data)
  const pagination = computed(() => data.value?.meta)

  watch([page, perPage], (newValue, oldValue) => {
    if (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) {
      if (process.client) document.documentElement.scrollTop = 0
      load()
    }
  })
  watchEffect(() => {
    if (page.value > pagination.value?.last_page) page.value = pagination.value.last_page
  })

  return {
    loading,
    pagination,
    page,
    items,
    load,
    error
  }
}
